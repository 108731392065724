import { getWMCAddress, getWMCSaleAddress, getPromoServiceAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import wmcABI from 'config/abi/wmc.json'
import wmcSaleABI from 'config/abi/wmcSale.json'
import promoServiceABI from 'config/abi/promoService.json'
import multicall from 'utils/multicall'

const getAffiliate = (id) => {
  let affilate = 5
  if (id <= 1000)
  affilate = 10
  if (id <= 400)
  affilate = 15
    if (id <= 100)
    affilate = 20
  return affilate;
}

const fetchMembershipUserData = async (account) => {
  // retrieve booster data to get compute balance


  const calls = [
    // Balance of token in the LP contract
    {
      address: getWMCAddress(),
      name: 'getCodeByAddress',
      params: [account],
    }
  ]

  const [
    _code
  ] = await multicall(wmcABI, calls);

  const calls2 =  [
    // Balance of token in the LP contract
    {
      address: getWMCAddress(),
      name: 'getTokenIdByCode',
      params: [_code[0]],
    }
  ]

  let _tokenId;
  if (_code[0] !== "0x00000000")
    [ _tokenId ]= await multicall(wmcABI, calls2);

  


  let _ownerOf = "0x0"
  let _bonus = 0
  if (_tokenId !== undefined) {
    const calls3 =  [
      // Balance of token in the LP contract
      {
        address: getWMCAddress(),
        name: 'ownerOf',
        params: [_tokenId[0]._hex],
      }
    ];
    [ _ownerOf ]= await multicall(wmcABI, calls3);

    _bonus = getAffiliate(_tokenId[0])

  }

  const calls4 = [
    // get current maximum card index id
    {
      address: getWMCAddress(),
      name: 'cardIndex',
      params: [0],
    },
    {
      address: getWMCAddress(),
      name: 'cardIndex',
      params: [1],
    },
    {
      address: getWMCAddress(),
      name: 'cardIndex',
      params: [2],
    },
    {
      address: getWMCAddress(),
      name: 'cardIndex',
      params: [3],
    }
  ]

  // get all cards
  const [
    standardID,
    blackID,
    goldID,
    bitID

  ] = await multicall(wmcABI, calls4); 

  const calls5 = [];
    // generate call for earning iterations
    for (let i = 1001; i < standardID; i++){
      calls5.push({
        address: getWMCSaleAddress(),
        name: 'cardEarnings',
        params: [i],
      });
    }
    for (let i = 401; i < blackID; i++){
      calls5.push({
        address: getWMCSaleAddress(),
        name: 'cardEarnings',
        params: [i],
      });
    }
    for (let i = 101; i < goldID; i++){
      calls5.push({
        address: getWMCSaleAddress(),
        name: 'cardEarnings',
        params: [i],
      });
    }
    for (let i = 1; i < bitID; i++){
      calls5.push({
        address: getWMCSaleAddress(),
        name: 'cardEarnings',
        params: [i],
      });
    }
  
  const earnings = await multicall(wmcSaleABI, calls5); 
  
  
  const mappedEarnings = calls5.map((call, index) => {
    return {
      id: call.params[0], // Assuming the ID is in the first position of the params array
      value: earnings[index], // Assuming the order of earnings corresponds to the order of blockchainCallsArray
    };
  });

  // Filter out values with earnings equal to 0
const filteredMappedEarnings = mappedEarnings.filter(entry => new BigNumber(entry.value).toNumber() !== 0);

const calls6 = filteredMappedEarnings.map(entry => {
  return {
    address: getWMCAddress(),
    name: 'ownerOf',
    params: [entry.id],
  };
});

const addresses = await multicall(wmcABI, calls6); 

const allData = filteredMappedEarnings.map((top, index) => {
  return {
    id: top.id, 
    value: new BigNumber(top.value[0]._hex).toJSON(), 
    address: addresses[index][0]
  };
});
// Sort the filteredMappedEarnings array based on earnings values in descending order
const allDataSorted = allData.sort((a, b) => new BigNumber(b.value).minus(new BigNumber(a.value)).toNumber());
const mappedData = allDataSorted.filter(entry => entry.address === account)
const totalUseEarnings = mappedData.reduce((sum, entry) => sum.plus(new BigNumber(entry.value)), new BigNumber(0));

const calls7 = [
  {
    address: getPromoServiceAddress(),
    name: 'hasClaim',
    params: [account],
  },
  {
    address: getPromoServiceAddress(),
    name: 'getID',
    params: [account],
  }
]

const [hasClaim, cardId ] = await multicall(promoServiceABI, calls7); 

const calls8 = [];
// generate call for earning iterations
for (let i = 1001; i < standardID; i++){
  calls8.push({
    address: getWMCAddress(),
    name: 'ownerOf',
    params: [i],
  });
}
for (let i = 401; i < blackID; i++){
  calls8.push({
    address: getWMCAddress(),
    name: 'ownerOf',
    params: [i],
  });
}
for (let i = 101; i < goldID; i++){
  calls8.push({
    address: getWMCAddress(),
    name: 'ownerOf',
    params: [i],
  });
}
for (let i = 1; i < bitID; i++){
  calls8.push({
    address: getWMCAddress(),
    name: 'ownerOf',
    params: [i],
  });
}

const tokenId = _tokenId === undefined ? 0 : new BigNumber(_tokenId[0]._hex).toNumber();
const cards = await multicall(wmcABI, calls8); 
const cardData = calls8.map((call, index) => {
  return {
    id: call.params[0], 
    address: cards[index][0], 
    code: call.params[0] === tokenId ? _code[0] : "n/a",
    affiliate: getAffiliate(call.params[0])
  };
});
const filteredCards = cardData.filter(entry => entry.address === account);


  return {
    affiliateCode: _code[0],
    tokenId: _tokenId !== undefined ? new BigNumber(_tokenId[0]._hex).toJSON() : "0",
    isOwner: _ownerOf[0] === account,
    bonus: _bonus,
    earningsAll: new BigNumber(totalUseEarnings).toJSON(),
    claimable: hasClaim[0],
    cardIdToClaim: new BigNumber(cardId).toJSON(),
    cardsData: filteredCards
  }
}

export default fetchMembershipUserData