const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },
  bitgold: {
    symbol: 'Bitgold',
    address: {
       1: '0x2b1E1E8e1D821564016bBC4383D1159cd37b06A4',  // Bitgold
       5: '0x9A3e54652cc2879b52305aB4FB4fCfdB415A691d',  // Bitgold
      56: '0xE0B58022487131eC9913C1F3AcFD8F74FC6A6C7E',  // GOLD NUGGET
      97: '0x7B3b8D203b0F5671caa031a41858eC39a08921E3',  // Bitgold
    },
    decimals: 18,
    projectLink: 'https://wildlands.me',
  },
  weth: {
    symbol: 'WETH',
    address: {
      1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', // wrapped eth
      5: '0x8c46B7fdC6db2B4cCBe0f4C3B5326465143DDC20', // TEST TOKEN // '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6', // wrapped eth
      56: '', // NONE
      97: '', // NONE
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      1: '', // NONE
      5: '', // NONE
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', // bnb
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd', // bnb
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      5: '',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      1: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      5: '',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  uni: {
    symbol: 'UNI',
    address: {
      1: '',
      5: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
      56: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://uniswap.org/',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      1: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
      5: '',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee' // Secondary: 0x8301f2213c0eed49a7e28ae4c3e91722919b8b47',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      5: '',
      56: '',
      97: '0xed24fc36dee211ea25a80239fb8c4cfd80f12ee' // Secondary: 0x8301f2213c0eed49a7e28ae4c3e91722919b8b47',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  test: {
    symbol: 'TEST_V2_Token',
    address: {
      1: '0x4Fabb145d64652a948d7253323f6E7A623C7C53',
      5: '0x95504279b1777167722c511e9258eeA3F34900B1',
      56: '',
      97: ''
    },
    decimals: 18,
    projectLink: '',
  },
}

export default tokens
