import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import wildlandsmineABI from 'config/abi/wildlandsmine.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress, getCommunityAddress } from 'utils/addressHelpers'
import farmsConfig, { offsetCommunity } from 'config/constants/farms'

const fetchFarms = async () => {
  const data = await Promise.all(
    farmsConfig.map(async (farmConfig) => {
      const lpAddress = getAddress(farmConfig.lpAddresses)
      
      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(farmConfig.token.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: getAddress(farmConfig.token.address),
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'decimals',
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
      ] = await multicall(erc20, calls)

      let info: any = null
      let infoMineDist: any = null
      let totalAllocPoint = 0
      if (!farmConfig.isCommunity) {
        // master contract
        [info, totalAllocPoint] = await multicall(masterchefABI, [
          {
            address: getMasterChefAddress(),
            name: 'poolInfo',
            params: [farmConfig.pid],
          },
          {
            address: getMasterChefAddress(),
            name: 'totalAllocPoint',
          },
        ])
      }
      else {
        // community contract (BitMine)
        [info, infoMineDist] = await multicall(wildlandsmineABI, [
        {
          address: getCommunityAddress(),
          name: 'mineInfo',
          params: [farmConfig.pid - offsetCommunity],
        },
        {
          address: getCommunityAddress(),
          name: 'mineDistribution',
          params: [farmConfig.pid - offsetCommunity],
        }
      ])
      totalAllocPoint = 100
      }

      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(new BigNumber(info.stakedAmount._hex)).div(new BigNumber(lpTotalSupply))

      // Total value in staking in quote token value
      const lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(18))
        .times(new BigNumber(2))
        .times(lpTokenRatio)

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
      const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)
      
      const allocPoint = !farmConfig.isCommunity ? new BigNumber(info.allocPoint._hex) : new BigNumber(100)
      const depositFeeBP = new BigNumber(info.depositFeeBP)
      const lockTimer = new BigNumber(info.lockTimer._hex);
      const _requireMembership = info.requireMembership;
      const _stakedAmount = new BigNumber(info.stakedAmount._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
      

      if (!farmConfig.isCommunity)
        return {
          ...farmConfig,
          tokenAmount: tokenAmount.toJSON(),
          quoteTokenAmount: quoteTokenAmount.toJSON(),
          lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
          tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
          poolWeight: poolWeight.toJSON(),
          multiplier: `${allocPoint.div(100).toString()}X (${poolWeight.multipliedBy(100).toFormat(0).toString()}%)`,
          depositFee: `${depositFeeBP.div(100).toString()}%`,
          blocksLocked: lockTimer.toString(),
          stakedAmount: _stakedAmount.toJSON(),
          requireMembership: _requireMembership,
        }
        return {
          ...farmConfig,
          tokenAmount: tokenAmount.toJSON(),
          quoteTokenAmount: quoteTokenAmount.toJSON(),
          lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
          tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
          poolWeight: poolWeight.toJSON(),
          multiplier: `${allocPoint.div(100).toString()}X (${poolWeight.multipliedBy(100).toFormat(0).toString()}%)`,
          depositFee: `${depositFeeBP.div(100).toString()}%`,
          blocksLocked: lockTimer.toString(),
          stakedAmount: _stakedAmount.toJSON(),
          requireMembership: _requireMembership,
          mine: {
            ...farmConfig.mine,
            startTimestamp: new BigNumber(infoMineDist.startTimestamp._hex).toString(),
            endTimestamp: new BigNumber(infoMineDist.endTimestamp._hex).toString(),
            rewardPerBlock: new BigNumber(infoMineDist.rewardPerSecond._hex).toString(),
          }
        }
    }),
  )
  return data
}

export default fetchFarms
