/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import fetchPresaleData from './fetchPresale'
import fetchPresaleUserData from './fetchPresaleUser'
import { PresaleState } from '../types'

const initialState: PresaleState = { data: {
  isIcoCompleted: false,
  hasIcoPaused: true,
  isIcoFirstRoundCompleted: false,
  isIcoSecondRoundCompleted: false,
  limitPhaseOne: undefined,
  limitPhaseTwo: undefined,
  limitPhaseThree: undefined,
  startSecondRoundTime: undefined,
  startThirdRoundTime: undefined,
  timeUntilCrowdsaleStarts: undefined,
  tokenAmountRateOne: undefined,
  tokenAmountRateTwo: undefined,
  tokenAmountRateThree: undefined,
  tokensRaised: undefined,
  has2ndRoundStarted: false,
  has3rdRoundStarted: false,
  minimumBuyAmount: undefined,
  whitelistSpots: undefined
} }

export const presaleSlice = createSlice({
  name: 'Presale',
  initialState,
  reducers: {
    setPresalePublicData: (state, action) => {
      const presaleData = action.payload
      // set presaledata as state
      state.data = presaleData
    },
    setPresaleUserData: (state, action) => {
      const  arrayOfUserDataObjects  = action.payload
      // add specific user data 
      state.data = { ...state.data, userData: arrayOfUserDataObjects }
    },
  },
})

// Actions
export const { setPresalePublicData, setPresaleUserData } = presaleSlice.actions

// Thunks
export const fetchPresalePublicDataAsync = () => async (dispatch) => {
  const data = await fetchPresaleData()
  dispatch(setPresalePublicData(data))
}

export const fetchPresaleUserDataAsync = (account) => async (dispatch) => {
  const userData = await fetchPresaleUserData(account)
  dispatch(setPresaleUserData(userData ))
}

export default presaleSlice.reducer
