// Set of helper functions to facilitate wallet setup

import { nodes } from './getRpcUrl'

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */


/* export const setupNetwork = async () => {
  // console.log("ID 0")
  const provider = (window as WindowChain).ethereum
  if (provider) {
    // console.log("ID ")
    // console.log(process.env.REACT_APP_CHAIN_ID)
    const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)

    try {
	
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: 'Ethereum Mainnet',
            nativeCurrency: {
              name: 'Ethereum Mainnet',
              symbol: 'ETH',
              decimals: 18,
            },
            rpcUrls: nodes,
            blockExplorerUrls: ['https://etherscan.io'],
          },
        ],
      }) 
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error("Can't setup the ETH network on metamask because window.ethereum is undefined")
    return false
  }
} */

  export const setupNetwork = async () => {
    const provider = (window as WindowChain).ethereum;
    if (provider) {
      const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10); // Ethereum Mainnet ID (likely 1)
      
      try {
        // Check the current network the user is on
        const currentChainId = await provider.request({ method: 'eth_chainId' });
        if (parseInt(currentChainId, 16) === chainId) {
          // User is already on the correct network (Ethereum Mainnet)
          return true;
        } 
        // User is on a different network, prompt them to switch
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
        return true;
      } catch (error) {
        // If the network is not added, attempt to add it
        if (error.code === 4902) {
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${chainId.toString(16)}`,
                  chainName: 'Ethereum Mainnet',
                  nativeCurrency: {
                    name: 'Ethereum',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  rpcUrls: nodes, // Ensure you have defined your `nodes` array with Ethereum RPC URLs
                  blockExplorerUrls: ['https://etherscan.io'],
                },
              ],
            });
            return true;
          } catch (addError) {
            console.error('Failed to add the network:', addError);
            return false;
          }
        } 
        console.error('Failed to switch the network:', error);
        return false;
      }
    } else {
      console.error("Can't setup the ETH network on metamask because window.ethereum is undefined");
      return false;
    }
  };

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string,
) => {
  const tokenAdded = await (window as WindowChain).ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage,
      },
    },
  })

  return tokenAdded
}
