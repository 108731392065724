import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const mainNetChainId = 56
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[mainNetChainId]
}
export const getBitgoldAddress = () => {
  return getAddress(tokens.bitgold.address)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getCommunityAddress = () => {
  return getAddress(addresses.community)
}
export const getPresaleAddress = () => {
  return getAddress(addresses.presale)
}
export const getWMCSaleAddress = () => {
  return getAddress(addresses.wmcSale)
}
export const getPromoServiceAddress = () => {
  return getAddress(addresses.promoService)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall)
}
export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address)
}
export const getLotteryAddress = () => {
  return getAddress(addresses.lottery)
}
export const getLotteryTicketAddress = () => {
  return getAddress(addresses.lotteryNFT)
}
export const getWMCAddress = () => {
  return getAddress(addresses.wmc);
}
export const getNFCAddress = () => {
  return getAddress(addresses.nfc);
}
export const getLiquidityAddress = () => {
  return getAddress(addresses.liquidity);
}
export const getVestingAddress = () => {
  return getAddress(addresses.vesting);
}
export const getMigrationAddress = () => {
  return getAddress(addresses.migration);
}