import tokens from './tokens'
import { FarmConfig, MineCategory } from './types'

export const offsetCommunity = 100

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'Bitgold',
    lpAddresses: {
      1: '0x2b1E1E8e1D821564016bBC4383D1159cd37b06A4',
      5: '0x9A3e54652cc2879b52305aB4FB4fCfdB415A691d',  // Bitgold
      97: '0x7B3b8D203b0F5671caa031a41858eC39a08921E3', // Bitgold
      56: '0xE0B58022487131eC9913C1F3AcFD8F74FC6A6C7E', // GOLD NUGGET
    },
    token: tokens.bitgold,
    quoteToken: tokens.bitgold,
    mineCategory: MineCategory.SINGLE,
    isCommunity: false,
  }, 
  /* {
    pid: 1,
    lpSymbol: 'Bitgold-WETH',
    lpAddresses: {
      1: '',
      5: '0x95504279b1777167722c511e9258eeA3F34900B1', // TEST TOKEN
      97: '0x705208ac112abBF47DbF8fC2dA7DDd850F4d0549', // Bitgold-BUSD
      56: '0x93ebebff79C504e1F0B6946A6F2fe693E811470A', // NUGGET-WBNB
    },
    token: tokens.bitgold,
    quoteToken: tokens.weth,
    mineCategory: MineCategory.LP,
    isCommunity: false
  }, 
  
  {
    pid: offsetCommunity + 0,
    lpSymbol: 'Bitgold',
    lpAddresses: {
      1: '',
      5: '0x9A3e54652cc2879b52305aB4FB4fCfdB415A691d', // TEST TOKEN
      97: '0x705208ac112abBF47DbF8fC2dA7DDd850F4d0549', // Bitgold-BUSD
      56: '0x93ebebff79C504e1F0B6946A6F2fe693E811470A', // NUGGET-WBNB
    },
    token: tokens.bitgold, // staking token
    quoteToken: tokens.test,
    mineCategory: MineCategory.SINGLE,
    isCommunity: true,
    mine: {
      earnToken: tokens.test
    }
  }
  /*
  {
    pid: 2,
    lpSymbol: 'DAI-USDC',
    lpAddresses: {
      97: '0x705208ac112abBF47DbF8fC2dA7DDd850F4d0549',
      56: '0x14d845F5467d1548fADBFD416dda11911C320798',
    },
    token: tokens.bitgold,
    quoteToken: tokens.busd,
  },
  {
    pid: 3,
    lpSymbol: 'BTC-USDC',
    lpAddresses: {
      97: '0x705208ac112abBF47DbF8fC2dA7DDd850F4d0549',
      56: '0xd86711220077614097593d611cfe08f12833daba',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'ETH-USDC',
    lpAddresses: {
      97: '0x705208ac112abBF47DbF8fC2dA7DDd850F4d0549',
      56: '0x50EF5ccB4E34b751B25e9F449Dc58C248554c465',
    },
    token: tokens.btcb,
    quoteToken: tokens.bitgold,
  } ,
  {
    pid: 6,
    lpSymbol: 'BTC-ETH',
    lpAddresses: {
      97: '0x705208ac112abBF47DbF8fC2dA7DDd850F4d0549',
      56: '0x46Da2455D212bf8BC78D5fE557074dc7602969C0',
    },
    token: tokens.btcb,
    quoteToken: tokens.bitgold,
  } */
]

export default farms