import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'
// Contract address == address that you wish to interact with (like master contract, not token address)
const pools: PoolConfig[] = [
  /* {
    sousId: 0,
    stakingToken: tokens.bitgold,
    earningToken: tokens.bitgold,
    contractAddress: {
      1: '',
      5: '0x5bC5DCD85655C2262CF76CBAFC55bD9458ffdD92',
      97: '0x9481e813B60B08DC4ea0Df9D98F77ec19A2b8E9f', // MASTER
      56: '0x6CB1Cdbae9a20413e37aF1491507cd5faE2DdD3e', // MASTER
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.140625',
    sortOrder: 1,
    isFinished: false,
  } */
]

export default pools
