import { getWMCSaleAddress, getWMCAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import wmcABI from 'config/abi/wmc.json'
import wmcSaleABI from 'config/abi/wmcSale.json'
import multicall from 'utils/multicall'

const fetchMembershipData = async () => {
  // retrieve presale data

  const calls = [
    // get current maximum card index id
    {
      address: getWMCAddress(),
      name: 'cardIndex',
      params: [0],
    },
    {
      address: getWMCAddress(),
      name: 'cardIndex',
      params: [1],
    },
    {
      address: getWMCAddress(),
      name: 'cardIndex',
      params: [2],
    },
    {
      address: getWMCAddress(),
      name: 'cardIndex',
      params: [3],
    }
  ]

  const [
    standardID,
    blackID,
    goldID,
    bitID

  ] = await multicall(wmcABI, calls); 

  // Initialize an array to store the blockchain calls
  const calls2 = [];

  // generate call for earning iterations
  for (let i = 1001; i < standardID; i++){
    calls2.push({
      address: getWMCSaleAddress(),
      name: 'cardEarnings',
      params: [i],
    });
  }
  for (let i = 401; i < blackID; i++){
    calls2.push({
      address: getWMCSaleAddress(),
      name: 'cardEarnings',
      params: [i],
    });
  }
  for (let i = 101; i < goldID; i++){
    calls2.push({
      address: getWMCSaleAddress(),
      name: 'cardEarnings',
      params: [i],
    });
  }
  for (let i = 1; i < bitID; i++){
    calls2.push({
      address: getWMCSaleAddress(),
      name: 'cardEarnings',
      params: [i],
    });
  }

const earnings = await multicall(wmcSaleABI, calls2); 


const mappedEarnings = calls2.map((call, index) => {
  return {
    id: call.params[0], // Assuming the ID is in the first position of the params array
    value: earnings[index], // Assuming the order of earnings corresponds to the order of blockchainCallsArray
  };
});

// Filter out values with earnings equal to 0
const filteredMappedEarnings = mappedEarnings.filter(entry => new BigNumber(entry.value).toNumber() !== 0);

// Sort the filteredMappedEarnings array based on earnings values in descending order
const sortedFilteredMappedEarnings = filteredMappedEarnings.sort((a, b) => new BigNumber(b.value).minus(new BigNumber(a.value)).toNumber());

// Get the top 50 values excluding those with earnings equal to 0
const top10Earnings = sortedFilteredMappedEarnings.slice(0, 50);

const calls3 = top10Earnings.map(entry => {
  return {
    address: getWMCAddress(),
    name: 'ownerOf',
    params: [entry.id],
  };
});

const addresses = await multicall(wmcABI, calls3); 

const mappedData = top10Earnings.map((top, index) => {
  return {
    id: top.id, 
    value: new BigNumber(top.value[0]._hex).toJSON(), 
    address: addresses[index][0]
  };
});

const calls4 = [
  // get current maximum card index id
  {
    address: getWMCSaleAddress(),
    name: 'saleOpenTimestamp',
    params: [],
  },
  {
    address: getWMCSaleAddress(),
    name: 'saleCloseTimestamp',
    params: [],
  },
  {
    address: getWMCSaleAddress(),
    name: 'isSalesManAround',
    params: [],
  }
]

const [timeOpen, timeClose,isSalesManAround] = await multicall(wmcSaleABI, calls4); 


  return {
    earnings: mappedData,
    timeOpen: new BigNumber(timeOpen[0]._hex).multipliedBy(1000).toJSON(),
    timeClose: new BigNumber(timeClose[0]._hex).multipliedBy(1000).toJSON(),
    standardId: new BigNumber(standardID[0]._hex).toJSON(),
    blackId: new BigNumber(blackID[0]._hex).toJSON(),
    goldId: new BigNumber(goldID[0]._hex).toJSON(),
    bitId: new BigNumber(bitID[0]._hex).toJSON(),
    isSalesManAround: isSalesManAround[0]
  }
}

export default fetchMembershipData;

