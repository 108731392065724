import { getPresaleAddress, getVestingAddress, getMigrationAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import vestingABI from 'config/abi/vesting.json'
import presaleABI from 'config/abi/presale.json'
import multicall from 'utils/multicall'

const fetchPresaleUserData = async (account) => {
  // retrieve booster data to get compute balance

  const calls = [
    // Balance of token in the LP contract
    {
      address: getVestingAddress(),
      name: 'vestedTokens',
      params: [account, true],
    },
    {
      address: getVestingAddress(),
      name: 'vests',
      params: [account],
    },
    {
      address: getVestingAddress(),
      name: 'countdown',
      params: [account],
    }
  ]

  const calls2 = [
    {
      address: getPresaleAddress(),
      name: 'whiteListed',
      params: [account],
    }
  ]

  const calls3 = [
    // Balance of token in the LP contract
    {
      address: getMigrationAddress(),
      name: 'vestedTokens',
      params: [account, true],
    },
    {
      address: getMigrationAddress(),
      name: 'vests',
      params: [account],
    },
    {
      address: getMigrationAddress(),
      name: 'countdown',
      params: [account],
    }
  ]


  const [
     vestedTokens,
     vests,
     countdown
  ] = await multicall(vestingABI, calls)

  const [
    vestedTokensM,
    vestsM,
    countdownM
 ] = await multicall(vestingABI, calls3)

  const [
    _whiteListed,
 ] = await multicall(presaleABI, calls2)

  return {
    whiteListed: _whiteListed[0],
    vestedTokens: new BigNumber(vestedTokens).toJSON(),
    transferred: new BigNumber(vests.transferred._hex).toJSON(),
    value: new BigNumber(vests.value._hex).toJSON(),
    isVestable: new BigNumber(vestedTokens).toJSON(),
    isLocked: new BigNumber(vests.value._hex).minus(new BigNumber(vests.transferred._hex)).toJSON(),
    countdown: new BigNumber(countdown).toJSON(),
    vestedTokensM: new BigNumber(vestedTokensM).toJSON(),
    transferredM: new BigNumber(vestsM.transferred._hex).toJSON(),
    valueM: new BigNumber(vestsM.value._hex).toJSON(),
    isVestableM: new BigNumber(vestedTokensM).toJSON(),
    isLockedM: new BigNumber(vestsM.value._hex).minus(new BigNumber(vestsM.transferred._hex)).toJSON(),
    countdownM: new BigNumber(countdownM).toJSON(),
  }
}

export default fetchPresaleUserData