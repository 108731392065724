/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { PriceApiResponse, PriceApiResponsePre, PriceState } from 'state/types'
import lpTokenABI from 'config/abi/lpToken.json'
import multicall from 'utils/multicall'
import tokens from 'config/constants/tokens'
import { PriceConfig } from 'config/constants/types'
import { getAddress } from 'utils/addressHelpers'

const initialState: PriceState = {
  isLoading: false,
  lastUpdated: null,
  data: null,
}

const liqPools: PriceConfig[] = [
  {
    address: {
      97: '',
      56: '0x51e6d27fa57373d8d4c256231241053a70cb1d93',
      5: '',
      1: ''

    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
    ordering: true
  },
  {
    address: {
      97: '',
      56: '0x14d845F5467d1548fADBFD416dda11911C320798',
      5: '',
      1: ''
    },
    token: tokens.bitgold,
    quoteToken: tokens.busd,
    ordering: true
  },
  {
    address: {
      97: '',
      56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
      5: '',
      1: ''
    },
    token: tokens.btcb,
    quoteToken: tokens.busd,
    ordering: false
  },
]

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiResponse>('prices/fetch', async () => {
  // PATCH (Needs revision)
  // const response = await fetch('https://api.pancakeswap.info/api/v2/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c');
  // const wbnbPrice = (await response.json())
  // console.log("PRICE WBNB")
  // console.log(wbnbPrice)
  const scores: any = {};
  // scores.set("update_at", "0");
  // scores.set("data",  {});
  scores.update_at = "0";
  scores.data =   {};
  scores.data[tokens.busd.symbol.toLowerCase()] = {symbol: "busd", price: new BigNumber("1").toJSON() };
  
  // console.log("PRIEEE")
  const DEC = 10000
  const DDEC = 1000000
  const calls: any = [liqPools.length]
  for(let i = 0; i < liqPools.length; i++) {
    const _symbol = liqPools[i].token.symbol.toLowerCase()
    scores.data[_symbol] = {symbol: _symbol, price: 0 };
    const address = getAddress(liqPools[i].address);
    calls[i] = 
      // Balance of token in the LP contract
      {
        address, 
        name: 'getReserves',
        params: [],
      }
  }

  // get all results
  // const results = await multicall(lpTokenABI, calls);

  // console.log("RES")
  // console.log(results);

  // for(let i = 0; i < liqPools.length; i++) {
  //     const result = results[i]
  //     const _symbol = liqPools[i].token.symbol.toLowerCase()
  //     let price = 0;
  //     scores.data[_symbol] = {symbol: _symbol, price: 0 };
  //     if (liqPools[i].token === tokens.wbnb){
  //       price = result[1].mul(DEC).div(result[0]).toNumber()/DEC
  //       scores.data[_symbol] = {symbol: _symbol, price: price.toString() };
  //       scores.data[tokens.wbnb.symbol.toLowerCase()] = {symbol: "wbnb", price: new BigNumber("3").toJSON() };
  //       // return  {symbol: _symbol, price: price.toString() };
  //     }
  //     // else {
  //       const quoteToken = liqPools[i].quoteToken.symbol.toLowerCase();
  //       if (quoteToken !== undefined && scores.data[quoteToken] !== undefined){
  //         const priceQT = new BigNumber(scores.data[quoteToken].price).toNumber()
  //         // compute price of ohter pools
  //         if (liqPools[i].ordering) {
  //           // token is first, wbnb is second
  //           price = result[1].mul(DDEC * priceQT).div(result[0]).toNumber()/DDEC
  //         }
  //         else {
  //           price = result[0].mul(DDEC * priceQT).div(result[1]).toNumber()/DDEC
  //         }
  //         scores.data[_symbol] = {symbol: _symbol, price: price.toString() };
  //         // return  {symbol: _symbol, price: price.toString() };
  //       // }
  //     }
  // }
  
  // const price = scores[tokens.nugget.symbol.toLowerCase()].price;
  // scores.data[tokens.goldbar.symbol.toLowerCase()] =  {symbol: tokens.goldbar.symbol.toLowerCase(), price: new BigNumber(price).multipliedBy(213.33).toString() }

  // iterate results

  /// TEST NET

  const data = {
    update_at: "0",
    data: {
      busd: {symbol: "busd", price: "1"},
      bitgold: {symbol: "bitgold", price: "1750"},
      eth: {symbol: "eth", price: "2000"},
      weth: {symbol: "weth", price: "1750"},
      btcb: {symbol: "BTCB", price: "25000"},
      test_v2_token: {symbol: "test_v2_token", price: "2500000000"},
    }
}
// const response = await fetch('https://api.pancakeswap.info/api/v2/tokens') // fetch('https://api.pancakeswap.info/api/v2/tokens')
// const data2 = (await response.json()) as PriceApiResponsePre
// data2.data.
// console.log(scores.data)
// console.log(scores.data.wbnb);
// console.log(scores.data[tokens.wbnb.symbol.toLowerCase()]);
// console.log(Object.keys(scores.data));
return {
  update_at: data.update_at,
  data: Object.keys(data.data).reduce((accum, token) => {
    return {
      ...accum,
      [data.data[token].symbol.toLowerCase()]: data.data[token].price, // modified for V2 usage
    }
  }, {}),
}

  /// TEST NET
  const [
    wbnbBUSD, 
    bitcoinBUSD
  ] = await multicall(lpTokenABI, [
    {
      address: "0x51e6d27fa57373d8d4c256231241053a70cb1d93", // wbnb/busd price
      name: 'getReserves',
      params: [],
    },
    {
      address: "0xF45cd219aEF8618A92BAa7aD848364a158a24F33", // bitcoin busd price
      name: 'getReserves',
      params: [],
    }
  ])
  // console.log("PRICES")
  // console.log(new BigNumber(pearBNB[1].toString()).toString())
  // console.log(new BigNumber(wbnbBUSD[1].toString()).div(new BigNumber(wbnbBUSD[0].toString())))
 const _bnb = wbnbBUSD[1].mul(DEC).div(wbnbBUSD[0]).toNumber()/DEC
  const _bitcoin = bitcoinBUSD[1].mul(DEC).div(bitcoinBUSD[0]).toNumber()/DEC
  const data2 = {
      update_at: "0",
      data: {
        busd: {symbol: "busd", price: new BigNumber("1").toJSON()},
        wbnb: {symbol: "wbnb", price: (_bnb).toString()},
        btcb: {symbol: "BTCB", price: _bitcoin.toString()},
      }
  }
  // const response = await fetch('https://api.pancakeswap.info/api/v2/tokens') // fetch('https://api.pancakeswap.info/api/v2/tokens')
  // const data2 = (await response.json()) as PriceApiResponsePre
  // data2.data.
  // console.log(scores.data)
  // console.log(scores.data.wbnb);
  // console.log(scores.data[tokens.wbnb.symbol.toLowerCase()]);
  // console.log(Object.keys(scores.data));
  return {
    update_at: data.update_at,
    data: Object.keys(data.data).reduce((accum, token) => {
      return {
        ...accum,
        [data.data[token].symbol.toLowerCase()]: data.data[token].price, // modified for V2 usage
      }
    }, {}),
  }
})

export const pricesSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrices.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPrices.fulfilled, (state, action: PayloadAction<PriceApiResponse>) => {
      state.isLoading = false
      state.lastUpdated = action.payload.update_at
      state.data = action.payload.data
    })
  },
})

export default pricesSlice.reducer
