/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
// import BigNumber from 'bignumber.js'
import fetchMembershipUserData from './fetchMembershipUserData'
import fetchMembershipData from './fetchMembership'
import { MembershipState } from '../types'

const initialState: MembershipState = { data: {
  earnings: null, timeOpen: null, timeClose: null, standardId: 0, blackId: 0, goldId: 0, bitId: 0, isSalesManAround: false} }

export const membershipSlice = createSlice({
  name: 'Membership',
  initialState,
  reducers: {
    setMembershipPublicData: (state, action) => {
      const membershipData = action.payload
      state.data = membershipData
    },
    setMembershipUserData: (state, action) => {
      const  arrayOfUserDataObjects  = action.payload
      // add specific user data 
      state.data = { ...state.data, userData: arrayOfUserDataObjects }
    },
  },
})

// Actions
export const { setMembershipPublicData, setMembershipUserData } = membershipSlice.actions

// Thunks

export const fetchMembershipPublicDataAsync = () => async (dispatch) => {
  const data = await fetchMembershipData()
  dispatch(setMembershipPublicData(data))
}

export const fetchMembershipUserDataAsync = (account) => async (dispatch) => {
  const userData = await fetchMembershipUserData(account)
  dispatch(setMembershipUserData(userData ))
}

export default membershipSlice.reducer
