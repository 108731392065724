import { TranslatableText } from 'state/types'


export enum PoolCategory {
  'COMMUNITY' = 'Friends',
  'CORE' = 'Core',
  'PARTNER' = 'Partner',
  'MEMBER' = "Member",
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
}

export enum MineCategory {
  'SINGLE' = 'Single',
  'LP' = 'LP'
}

export interface Address {
  97?: string
  56?: string
  5?: string
  1: string
}

export interface Token {
  symbol: string
  address?: Address
  decimals?: number
  projectLink?: string
}

export interface FarmConfig {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  token: Token
  quoteToken: Token
  multiplier?: string
  depositFee?: string
  blocksLocked?: number
  isCommunity?: boolean
  mineCategory: MineCategory
  requireMembership?: boolean
  stakedAmount?: number
  mine?: {
    rewardPerBlock?: number
    earnToken: Token
    startTimestamp?: number
    endTimestamp?: number
  }
}
export interface PriceConfig {
  address: Address
  token: Token
  quoteToken: Token
  ordering: boolean
}

export interface PoolConfig {
  sousId: number
  earningToken: Token
  stakingToken: Token
  debtToken?: Token,
  stakingLimit?: number
  contractAddress: Address
  poolCategory: PoolCategory
  tokenPerBlock: string
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}
