export default {
  masterChef: {
    1: '0x386E2d431429589168f464758a8Fb2d18b1E7b8D', // set
    5: '0x5bC5DCD85655C2262CF76CBAFC55bD9458ffdD92',
    97: '0x9481e813B60B08DC4ea0Df9D98F77ec19A2b8E9f',
    56: '0x6CB1Cdbae9a20413e37aF1491507cd5faE2DdD3e',
  },
  community: {
    1: '',
    5: '0xe6824ADbe1dfa5FfE91DAA4C12130AA65127F130',
    97: '0x9481e813B60B08DC4ea0Df9D98F77ec19A2b8E9f',
    56: '0x6CB1Cdbae9a20413e37aF1491507cd5faE2DdD3e',
  },
  liquidity: {
    1: '',
    5: '0x95504279b1777167722c511e9258eeA3F34900B1',  // TEST TOKEN '0x28cee28a7C4b4022AC92685C07d2f33Ab1A0e122',  // Uni-WETH
    97: '0x705208ac112abBF47DbF8fC2dA7DDd850F4d0549', // Bitgold-BUSD
    56: '0xE0B58022487131eC9913C1F3AcFD8F74FC6A6C7E',
  },
  presale: {
    1: '0x9a46ca271ea95a70b4a9c6e989df9643b36be6df',
    5: '0x0011eB405A1E3773287b188314504bd92bb24154',
    97: '0x537B7715c45e9a6154f4f6D68984db0DCC47b1f9',
    56: '0xBFd0601557F4BE1953fDcDd9bc6e2D559BCDC2cC',
  },
  wmcSale: {
    1: '0x3e7bc4A2025fd162Fcdb960e05E65912E13e0aE4', // '0x3e7bc4A2025fd162Fcdb960e05E65912E13e0aE4',
    5: '0x6696934003e3d17f4418f1254fbef8f471a8299a',
    97: '',
    56: '',
  },
  promoService: {
    1: '0xD8eb4990492284B9588f21F6CDa20fCf56352Af6', 
    5: '0xA823112B589EcC7b6cd02F8Ab68d6D045E3FBcD1',
    97: '',
    56: '',
  },
  vesting: {
    1: '0x8b2ad260e2eb6418c29d0c4fdef7e1d927eb27d0',
    5: '0xeFbb62F4B73D29F33Ec897b967bA085987FC7528',
    97: '0xCD028361217659c2b61E24A6bAbd096699A80767',
    56: '0x0E3b28e1061D938B6b903A3Fd884f843C57D27e6',
  },
  migration: {
    1: '0x8b2ad260e2eb6418c29d0c4fdef7e1d927eb27d0',
    5: '0xeFbb62F4B73D29F33Ec897b967bA085987FC7528',
    97: '0xCD028361217659c2b61E24A6bAbd096699A80767',
    56: '0x0E3b28e1061D938B6b903A3Fd884f843C57D27e6',
  },
  wmc: {
    1: '0x62061b764EC66FE1Bd8910b1Af780043976A6c68',
    5: '0x95C21AaB02daE8D7e5d5a7Ec126BcA425dC612dE',
    97: '0x0D7D5c15641A7bB718CB6238703973c5Ff4b9927', // WMC
    56: '0x69799454f8c742690E20a34B953521fdA013159E', // NFC
  },
  nfc: {
    1: '', // NONE
    97: '', // NONE
    56: '0x69799454f8c742690E20a34B953521fdA013159E', // NFC
  },
  lottery: {
    1: '', // NONE
    5: '', // NONE
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    1: '', // NONE
    5: '', // NONE
    97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    1: '0xF0C9950F46540F2D3fF3C64f534AD32c137D5464',
    5: '0x88F862bcDd9e20Bce3eD35d6Eb45028AeAae6851',
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
}
