import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { kebabCase } from 'lodash'
import { Toast, toastTypes } from '@pancakeswap-libs/uikit'
import { useSelector, useDispatch } from 'react-redux'
import { MineCategory } from 'config/constants/types'
import { getWeb3NoAccount } from 'utils/web3'
import useRefresh from 'hooks/useRefresh'
import { getBalanceNumber } from 'utils/formatBalance'
import {
  fetchFarmsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  fetchMembershipUserDataAsync,
  fetchMembershipPublicDataAsync,
  fetchPresaleUserDataAsync,
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
  setBlock,
  fetchPresalePublicDataAsync,
} from './actions'
import { State, Farm, Pool, Block, PriceState, Presale, Membership } from './types'
import { fetchPrices } from './prices'


export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh, fastRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchPoolsPublicDataAsync())
    dispatch(fetchPresalePublicDataAsync())
    dispatch(fetchMembershipPublicDataAsync())
  }, [dispatch, slowRefresh])

  useEffect(() => {
    dispatch(fetchPrices())
  }, [dispatch, fastRefresh])

  useEffect(() => {
    const web3 = getWeb3NoAccount()
    const interval = setInterval(async () => {
      const blockNumber = await web3.eth.getBlockNumber()
      dispatch(setBlock(blockNumber))
    }, 6000)

    return () => clearInterval(interval)
  }, [dispatch])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
    locked: farm.userData ? new BigNumber(farm.userData.locked) : new BigNumber(0),
    unlocked: farm.userData ? new BigNumber(farm.userData.unlocked) : new BigNumber(0),
    earliestUnlock: farm.userData ? new BigNumber(farm.userData.earliestUnlock) : new BigNumber(0)
  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

export const usePoolUser = (pid) => {
  const pool = usePoolFromPid(pid)
  return {
    allowance: pool.userData ? new BigNumber(pool.userData.allowance) : new BigNumber(0),
    tokenBalance: pool.userData ? new BigNumber(pool.userData.stakingTokenBalance) : new BigNumber(0),
    stakedBalance: pool.userData ? new BigNumber(pool.userData.stakedBalance) : new BigNumber(0),
    earnings: pool.userData ? new BigNumber(pool.userData.pendingReward) : new BigNumber(0),
    locked: pool.userData ? new BigNumber(pool.userData.locked) : new BigNumber(0),
    unlocked: pool.userData ? new BigNumber(pool.userData.unlocked) : new BigNumber(0),
    earliestUnlock: pool.userData ? new BigNumber(pool.userData.earliestUnlock) : new BigNumber(0),
    pendingRewardUnlocked: pool.userData ? new BigNumber(pool.userData.pendingRewardUnlocked) : new BigNumber(0)
  }
}

// Toasts
export const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPrices())
  }, [dispatch, slowRefresh])
}

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data)
  return prices
}

export const useGetApiPrice = (token: string) => {
  const prices = useGetApiPrices()
  // console.log(prices)
  if (!prices) {
    return null
  }

  // compensation token is pegged with bnb value 1:1
  if (token.toLowerCase() === "compensation")
    return prices.wbnb;

  return prices[token.toLowerCase()]
}


export const usePriceBnbBusd = (): BigNumber => {
  const ZERO = new BigNumber(0)
  const pid = 2 // BUSD-BNB LP
  const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
  return new BigNumber(10);
}

export const usePriceCakeBusd = (): BigNumber => {
  const ZERO = new BigNumber(0)
  const bitgoldETHFarm = useFarmFromPid(1)
  // using 
  // const busdPrice = useGetApiPrice("busd"); // useFarmFromPid(1) // TODO: CHANG BACK TO BUSD
  const wethPrice = useGetApiPrice("weth");
  return new BigNumber("1000");
  // const nuggetBusdPrice = cakeBusdFarm.tokenPriceVsQuote ? new BigNumber(1).times(1).times(cakeBusdFarm.tokenPriceVsQuote) : ZERO
  const bitgoldEthPrice = bitgoldETHFarm.tokenPriceVsQuote ? new BigNumber(bitgoldETHFarm.tokenPriceVsQuote).times(wethPrice) : ZERO
  const tokenETH = bitgoldETHFarm.tokenAmount? new BigNumber(bitgoldETHFarm.tokenAmount): ZERO;
  return bitgoldEthPrice;
}

// Block
export const useBlock = (): Block => {
  return useSelector((state: State) => state.block)
}

export const useTotalValue = (): BigNumber => {
  const farms = useFarms();
  const pools = useSelector((state: State) => state.pools.data);
  const nuggetPrice = usePriceCakeBusd();
  // get price list from underlying api
  const prices = useGetApiPrices()
  if (prices === null)
    return new BigNumber(0);
  let value = new BigNumber(0);
  // iterate over farm list
  for (let i = 0; i < farms.length ; i++) {
    const farm = farms[i]

      if (farm.mineCategory === MineCategory.LP) {
        if (farm.lpTotalInQuoteToken) {
          // get locked value of current farm
          if (farm.quoteToken.symbol.toLowerCase() !== "bitgold")
            value = value.plus(new BigNumber(farm.lpTotalInQuoteToken).times(prices[farm.quoteToken.symbol.toLowerCase()]));
          else
          value = value.plus(new BigNumber(farm.lpTotalInQuoteToken).times(nuggetPrice));
        }
      }
      else if (farm.mineCategory === MineCategory.SINGLE) {
        if (!farm.isCommunity) {
          // get staking token prie
          let stakingTokenPrice = prices[farm.token.symbol.toLowerCase()]
          stakingTokenPrice = farm.pid === 0 ? nuggetPrice.toNumber() : stakingTokenPrice;
          // compute liqudity and apy
          value = value.plus(new BigNumber(farm.stakedAmount).times(stakingTokenPrice).div(new BigNumber(10).pow(farm.token.decimals)))
          
        }
        else {
          value = value.plus(new BigNumber(farm.stakedAmount).times(nuggetPrice).div(new BigNumber(10).pow(farm.token.decimals)))
          
        }
      }
  }
  if (prices !== null && prices !== undefined){
    for (let i = 0; i < pools.length; i++) {
      const pool = pools[i]
      const totalStaked = getBalanceNumber(pool.totalStaked, pool.stakingToken.decimals);
      const symbol = pool.stakingToken.symbol.toLowerCase();
      let priceToken;
      if (symbol === "bitgold")
        priceToken = nuggetPrice;
      else{
        priceToken = prices[symbol];
      }
      if (priceToken){
        value = value.plus(new BigNumber(pool.totalStaked ? totalStaked : 0).times(priceToken));
      }
    }
  }
  return value // value;
}

// Presale

export const usePresale = (account): Presale => {

  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPresaleUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const presale = useSelector((state: State) => state.presale.data)
  return presale
}

// Membership

export const useMembership = (account): Membership => {

  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchMembershipUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const nuggetswapper = useSelector((state: State) => state.membership.data)
  return nuggetswapper
}
