import React, { useEffect, lazy } from 'react'
import styled from 'styled-components'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS} from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPriceList, useFetchPublicData } from 'state/hooks'
import CookieConsent from "react-cookie-consent";
import useGetDocumentTitlePrice from './hooks/useGetDocumentTitlePrice'
import GlobalStyle from './style/Global'
import HomeStyle from './style/Home'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader' 
import EasterEgg from './components/EasterEgg'
import history from './routerHistory'

 
// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Affiliate = lazy(() => import('./views/WMCSale'))
// const Presale = lazy(() => import('./views/Presale'))
// const Collectibles = lazy(() => import('./views/Index'))
const ComingSoon = lazy(() => import('./views/ComingSoon'))

const StyledMineBackground = styled.div`
background: url(../images/mine/blockmine-background.svg);
background-position: top;
background-size: 2080px;
background-repeat: repeat;
`

const StyledWMCSaleBackground = styled.div`
background: url(../images/presale/bg.PNG);
background-position: top;
background-size: 2080px;
background-repeat: repeat;
`

const StyledCoinflipBackground = styled.div`
background: url(../images/saloon/blockmine-coinflip-desk-goldcoin.webp);
background-position: -60px 0px;
background-color: #eec078;
background-size: 2080px;
background-repeat: no-repeat;
@media screen and (max-width: 1000px){
  {
    background-position: top center;
  }
}
`

const StyledStatsBackground = styled.div`
background: url(../images/stats/blockmine-stats.webp);
background-position: -60px 0px;
background-color: #eec078;
background-size: 2080px;
background-repeat: no-repeat;
@media screen and (max-width: 1000px){
  {
    background-position: top center;
  }
}
`

const StyledPresaleBackground = styled.div`
background: url(../images/presale/wildlands.jpg);
background-position: -0px 0px;
background-color: #eec078;
background-size: 100%;
background-height: 2000px;
background-repeat: no-repeat;
@media screen and (max-width: 1000px){
  {
    background-position: top center;
  }
}
.bg-container {
  font-family: "Anybody";
  background-image: url('/assets/images/main-img.png');
  background-size: auto;
  background-repeat: no-repeat;
  color: #fff;
  background-size: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Anybody" !important;
}

h1 {
  color: #fff !important;
  font-size: 42px !important;
  font-weight: lighter !important;
}

h2 {
  font-weight: bold !important;
  font-style: italic !important;
  font-size: 45px !important;
}

/* Navbar */
div#navbarNav {
  gap: 40px !important;
}

ul.navbar-nav {
  gap: 20px !important;
}

.white-space{
  white-space: break-spaces !important;
}

.nav-link {
  font-family: "Anybody" !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

li.nav-item a:hover {
  color: #f8f800 !important;
}

.btn-grad {
  font-family: "Anybody" !important;
  font-weight: bold !important;
  background-image: linear-gradient(to right, #f8db00 0%, #f7c900 35%, #f7b400 100%);
  margin: 10px;
  padding: 10px 22px !important;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #000000;
  border-radius: 55px;
  display: block;
  outline: none;
  border: 0;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

/* Hamburger Icon */
.navbar-dark .navbar-toggler-icon {
  filter: brightness(0) saturate(100%) invert(80%) sepia(96%) saturate(1814%) hue-rotate(9deg) brightness(114%) contrast(104%);
  background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);
}

/* Hamburger Icon finished */

/* Hero Section */
.hero-sec.pt-100 {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}

.content h1 {
  white-space: pre-line;
  text-align: center;
}

span.colored-txt {
  color: #f8f800;
}

/* Hero Sec End */

/* About Us */
.about-us.pt-100 {
  margin-top: 80px;
  margin-bottom: 100px;
}

/* About Us End */
/* progress bar  */
.progress-bar.bg-warning {
  border-radius: 50px;
  /* height: 145%; */
}

.about-us .progress {
  border: 4px solid #352161;
  height: 40px;
  border-radius: 50px;
  background: #0b0c27;
  width: 60%;
}

.range {
  gap: 80px;
}

.info {
  gap: 8px;
}

.info p {
  margin: unset;
}

.table-content {
  border: 1px solid #000000;
  border-radius: 39px;
  width: 70%;
}

.content-box {
  background: linear-gradient(125deg, rgba(71, 139, 180, 1) 15%, rgba(92, 37, 128, 1) 45%, rgba(31, 37, 123, 1) 100%, rgba(197, 172, 221, 1)-120%);
  padding: 10%;
  border-radius: 20px;
  max-width: 65%;
  margin: 0px auto;
  position: relative;
}

.content-box-wl {
  background: linear-gradient(125deg, rgba(251, 163, 62, 1) 15%, rgba(251, 163, 62, 1) 45%, rgba(251, 163, 62, 1) 100%, rgba(251, 163, 62, 1)-120%);
  padding: 10%;
  border-radius: 20px;
  max-width: 65%;
  margin: 0px auto;
  position: relative;
}


.box-1,
.box-2 {
  position: relative;
}

.content-box:hover h3 {
  color: #f8f800;
  transition: 0.5s;
}

ul.box-list {
  padding-left: unset;
  list-style: none;
  text-align: center;
}

.form-sec {
  margin-top: 100px;
}

.form-group {
  padding: 56px 14px;
  width: 57%;
  border-radius: 15px;
  background-image: url(/assets/images/form-bg.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}


.form-group input {
  background: #1c1a4eb3;
  outline: none;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.form-group-wl input {
  background: #1c1a4eb3;
  outline: none;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.form-sec h3 {
  font-weight: 600;
  font-style: italic;
}

button.btn.btn-outline-secondary.dropdown-toggle.mx-3 {
  background-image: linear-gradient(to right, #f8db00 0%, #f7c900 35%, #f7b400 100%);
  border-radius: 12px;
  color: #2e2830;
  font-weight: 800;
}

.bg-form {
  background-image: url(/assets/images/BG_form.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 70px;
  padding-bottom: 60px;
}

.bg-form-wl {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 70px;
  padding-bottom: 60px;
}

.dropdown-toggle::before {
  /* background-image: url(/assets/images/btn-icon.png); */
  content: "";
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;

}

.dropdown-toggle::before {
  vertical-align: middle;
  display: inline-block;
  /* background-image: url(/assets/images/btn-icon.png); */
  content: "";
  width: 25px;
  height: 21px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 99999;
  top: 0;
  background-size: contain;
}

.form-field-1 input::after {
  background-image: url(/assets/images/Asset\ 4.png);
}

.feature-sec {
  margin-top: 100px;
  text-align: center;
}

.feature-img img {
  width: 50%;
  margin: 0px auto;
}

.map-box {
  background: #2a19698c;
  padding: 12%;
  width: 60%;
  border-radius: 15px;
  margin: 0px auto;
}

.map-box h4 {
  font-size: 58px;
  color: #f8f800;

}

.map-box ul {
  padding-left: 0pt;
}

#roadmap {
  padding-top: 100px;
}

.map-box {
  background: #2a19698c;
  padding: 10%;
  width: 60% !important;
  border-radius: 15px;
}

.map-box h4 {
  font-size: 58px;
  color: #f8f800;

}

.map-box ul {
  padding-left: 0pt;
}

.box-row {
  background-image: url('/assets/images/stand.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px;

}

.box-row-2 {
  background-image: url('/assets/images/2023.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px;

}

.map-box {
  position: relative;
  z-index: -9999;
}

div#box-2 ul li {
  margin-left: 15%;
}

section.info-cards {
  background-position: center;
  content: "";
  background-image: url(/assets/images/bg-top.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.comment-box img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width:767px) and (min-width:320px) {
  .hero-img img {
      display: none;
  }

  li.nav-item a:hover {
      color: #f8f800 !important;
  }

  div#navbarNav {
      padding: 22px;
      background: #4d2075;
  }

  button.btn-grad {
      margin: 0;
  }

  .form-group {
      width: 100%;

  }
  
  .form-group-wl {
      width: 100%;

  }

  .right-box {
      float: unset !important;
      margin-right: auto !important;
      width: 100%;
  }

  .left-box {
      float: unset !important;
      margin-left: auto !important;
      width: 100%;
  }
}

#tokenomics {
  background-image: url(/assets/images/2022_bubbls.png);
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto;
}

.box-counter {
  margin: 0px auto;
  padding: 10px;
  max-width: 50%;
  background: linear-gradient(245deg, rgb(131, 104, 171) 9%, rgb(227, 223, 68) 89%, rgb(227, 223, 72) 95%);
  border-radius: 22px;

}

.right-box {
  float: right;
  margin-right: 2%;
  width: 100%;
}

.left-box {
  float: left;
  margin-left: 2%;
  width: 100%;
}

.box-counter h4 {
  text-align: center;
  padding-top: 30px;

}

.percent-box {
  border-radius: 0px 0px 15px 15px;
  width: 100%;
  background: #162f54f5;
  padding: 25%;
}

.percent-box h3 {
  font-weight: 300;
  font-size: 54px;
}

.navbar-toggle {
  border: none;
  outline: none;
}

ul.social-links li {
  background: white;
  padding: 8px 10px;
  border-radius: 12px;
}

ul.social-links li:hover {
  transition: 0.4s;
  background: linear-gradient(245deg, rgb(131, 104, 171) 9%, rgb(227, 223, 68) 89%, rgb(227, 223, 72) 95%);
}

ul.social-links li:hover i {
  filter: brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(7497%) hue-rotate(90deg) brightness(107%) contrast(99%);
}

@media only screen and (max-width:991px) and (min-width:768px) {
  .content-box {
      max-width: 90% !important;
  }

  .box-counter {
      max-width: 100% !important;
  }

  .map-box {
      width: 100% !important;
  }

  .hero-img img {
      object-fit: contain;
      width: 100%;
  }

  li.nav-item a:hover {
      color: #f8f800 !important;
  }

  div#navbarNav {
      padding: 22px;
      background: #4d2075;
  }

  button.btn-grad {
      margin: 0;
  }

  .range {
      gap: 0;
  }
}

ul.social-links {
  list-style: none;
  justify-content: center;
  display: flex;
  gap: 40px;
}

@media only screen and (max-width:1199px) and (min-width:992px) {
  a.navbar-brand {
      display: none;
  }
}

@media only screen and (max-width:1440px) and (min-width:1200px) {
  a.navbar-brand {
      display: none;
  }

  .about-content p {
      padding: 0 219px;
  }
}

@media only screen and (max-width:1920px) and (min-width:1441px) {
  a.navbar-brand {
      display: none;
  }

  .content p {
      display: block;
      white-space: pre;
  }

  .about-content p {
      padding: 0 219px;
  }
}
body {
  font-family: "Anybody";
  background-size: auto;
  background-repeat: no-repeat;
  color: #fff !important;
  background: #000000;
  background-repeat: no-repeat;
  background-size: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Anybody" !important;
}

h1 {
  color: #fff;
  font-size: 42px;
  font-weight: lighter;
}

h2 {
  font-weight: bold;
  font-style: italic;
  font-size: 45px;
}

/* Navbar */
div#navbarNav {
  gap: 40px !important;
}

ul.navbar-nav {
  gap: 20px;
}

li.nav-item a:hover {
  color: #f8f800 !important;
}

.btn-grad {
  font-weight: bold;
  background-image: linear-gradient(to right, #f8db00 0%, #f7c900 35%, #f7b400 100%);
  margin: 2rem auto 3rem auto !important;
  padding: 10px 22px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #000000;
  border-radius: 55px;
  display: block;
  outline: none;
  border: 0;
}


.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

/* Hamburger Icon */
.navbar-dark .navbar-toggler-icon {
  filter: brightness(0) saturate(100%) invert(80%) sepia(96%) saturate(1814%) hue-rotate(9deg) brightness(114%) contrast(104%);
  background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);
}

/* Hamburger Icon finished */

/* Hero Section */
.hero-sec.pt-100 {
  padding-top: 113px;
  display: flex;
  justify-content: center;
}

.content h1 {
  white-space: pre-line;
  text-align: center;
}

span.colored-txt {
  color: #f8f800;
}

/* Hero Sec End */

/* About Us */
.about-us.pt-100 {
  margin-top: 80px;
  margin-bottom: 100px;
}

/* About Us End */
/* progress bar  */
.progress-bar.bg-warning {
  border-radius: 50px;
  /* height: 145%; */
}

.about-us .progress {
  border: 4px solid #352161;
  height: 40px;
  border-radius: 50px;
  background: #0b0c27;
  width: 60%;
}

.presale .progress {
  border: 4px solid #352161;
  height: 40px;
  border-radius: 50px;
  background: #0b0c27;
  width: 60%;
}

.round .progress {
  border: 4px solid #352161;
  height: 40px;
  border-radius: 50px;
  background: #0b0c27;
}

.range {
  gap: 80px;
}

.info {
  gap: 8px;
}

.info p {
  margin: unset;
}

.table-content {
  padding: 10px 0px;
  margin: 3rem auto 6rem auto !important;
  border: 1px solid #000000;
  border-radius: 39px;
  width: 70%;
}

.content-box {
  background: linear-gradient(125deg, rgba(71, 139, 180, 1) 15%, rgba(92, 37, 128, 1) 45%, rgba(31, 37, 123, 1) 100%, rgba(197, 172, 221, 1)-120%);
  padding: 10%;
  border-radius: 20px;
  max-width: 75%;
  margin: 0px auto;
  position: relative;
}

.box-1::after {
  background-image: url(/assets/images/balls.png);
  content: "";
  width: 162px;
  height: 70px;
  position: absolute;
  top: -22%;
  z-index: -1;
  left: 24%;
}

.box-2::after {
  background-image: url(/assets/images/balls.png);
  content: "";
  width: 162px;
  height: 70px;
  position: absolute;
  top: -22%;
  z-index: -1;
  left: 24%;
}

.box-1,
.box-2 {
  position: relative;
}

.content-box:hover h3 {
  color: #f8f800;
  transition: 0.5s;
}

.content-box .text-center {
  font-size: 1.75rem;
}

ul.box-list {
  margin: 1rem 0rem;
  padding-left: unset;
  list-style: none;
  text-align: center;
}

.form-sec {
  margin-top: 100px;
}

.form-group {
  padding: 20px 21px;
  width: 70%;
  border-radius: 15px;
  background-image: url(/assets/images/form-bg.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.form-group-wl {
  padding: 20px 21px;
  width: 70%;
  border-radius: 15px;
  background-image: url(/assets/images/wildlands_bg.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.form-group input {
  color: #ffffff;
  background: #1c1a4eb3;
  outline: none;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.form-sec h3 {
  font-weight: 600;
  font-style: italic;
}

button.btn.btn-outline-secondary.dropdown-toggle.mx-3 {
  height: 45px;
  background-image: linear-gradient(to right, #f8db00 0%, #f7c900 35%, #f7b400 100%);
  border-radius: 12px;
  color: #2e2830;
  font-weight: 800;
}


.Text-sc-99a77088-0 .gwUrbS {
  color: black
}

.dropdown-toggle::before {
  /* background-image: url(/assets/images/btn-icon.png); */
  content: "";
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;

}

.dropdown-toggle::before {
  vertical-align: middle;
  display: inline-block;
  /* background-image: url(/assets/images/btn-icon.png); */
  content: "";
  width: 25px;
  height: 21px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 99999;
  top: 0;
  background-size: contain;
}

.form-field-1 input::after {
  background-image: url(/assets/images/Asset\ 4.png);
}

.feature-sec {
  margin-top: 100px;
  text-align: center;
}

.feature-img img {
  width: 50%;
  margin: 0px auto;
}

@media only screen and (max-width:767px) and (min-width:320px) {
  .hero-img img {
      display: none;
  }

  li.nav-item a:hover {
      color: #f8f800 !important;
  }

  div#navbarNav {
      padding: 22px;
      background: #4d2075;
  }

  button.btn-grad {
      margin: 0;
  }

  .form-group {
      width: 100%;

  }
}

#tokenomics span {
  font-weight: 500;
}

#tokenomics {
  margin: o auto;
}

.box-counter {
  padding: 10px;
  max-width: 50%;
  background: linear-gradient(245deg, rgb(131, 104, 171) 9%, rgb(227, 223, 68) 89%, rgb(227, 223, 72) 95%);
  border-radius: 22px;
  align-items: flex-end
}

.box-counter h4 {
  text-align: center;
  padding-top: 30px;

}

.percent-box {
  border-radius: 0px 0px 15px 15px;
  width: 100%;
  background: #162f54f5;
  padding: 25%;
}

.percent-box h3 {
  font-weight: 300;
  font-size: 54px;
}

@media only screen and (max-width:991px) and (min-width:768px) {
  .hero-img img {
      object-fit: contain;
      width: 100%;
  }

  li.nav-item a:hover {
      color: #f8f800 !important;
  }

  div#navbarNav {
      padding: 22px;
      background: #4d2075;
  }

  button.btn-grad {
      margin: 0;
  }

  .range {
      gap: 0;
  }
}

@media only screen and (max-width:1199px) and (min-width:992px) {
  a.navbar-brand {
      display: none;
  }
}

@media only screen and (max-width:1440px) and (min-width:1200px) {
  a.navbar-brand {
      display: none;
  }

  .about-content p {
      padding: 0 219px;
  }
}

@media only screen and (max-width:1920px) and (min-width:1441px) {
  a.navbar-brand {
      display: none;
  }

  .content p {
      display: block;
      white-space: pre;
  }

  .about-content p {
      padding: 0 219px;
  }
}
`

const StyledHomeBackground = styled.div`
#parallax-container {
  display: block;
  height: 0px;
}

#parallax-container div {
  position: fixed;
  background-position: top!important;
  transform: translateY(0px);
  height: 2680px;
  width: 1920px;
  z-index: -1;
  width: 100%;
  
  /* Spacing Layout Home */

  .heroMine{
  margin-top: -110px !important;
  margin-bottom: 330px !important;
}
}

.parallax-image-top {
    background: url(../img/blockmine-artwork.png);
}

@media screen and (max-width: 600px){
  .parallax-image-top{
    background: url(../img/blockmine-mobile.png);
    top: -150px;
  }
}

  
}
.parallax-image-bottom {
  background: url(../img/blockmine-artwork-back-comp.png);
}
`

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useEagerConnect()
  useFetchPublicData()
  useFetchPriceList()
  useGetDocumentTitlePrice()

  return (
    <Router history={history}>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cowboy_cookie"
        style={{ background: "#76363e" }}
        buttonStyle={{ fontSize: "15px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <ResetCSS />
      <GlobalStyle />
      <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <StyledWMCSaleBackground>
                  <GlobalStyle />
                  <Affiliate />
              </StyledWMCSaleBackground>    
            </Route>
            
            {/* 404 */}
            <StyledMineBackground>
              <Route component={ComingSoon} />
            </StyledMineBackground>
          </Switch>
        </SuspenseWithChunkError>
      <EasterEgg iterations={2} />
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
