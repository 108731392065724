import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: #000; //${({ theme }) => theme.colors.background};


    img {
      height: auto;
      max-width: 100%;
    }
  }

  /* Logo */
  @media only screen and (max-width: 900px) {
    .BlockmineLogo {
      width: 100% !important;
    }
  }
  
  /* Heading */

  .Mainheader{
  font-size: 42px;
  }
  
  .VideoButton {
    width: 90px;
    transition : width 0.3s ease;
  }
  
  .VideoButton:hover {
    width: 120px;
  }

`

export default GlobalStyle
