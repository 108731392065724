import { getPresaleAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import presaleABI from 'config/abi/presale.json'
import multicall from 'utils/multicall'

const fetchPresaleData = async () => {
  // retrieve presale data

  const calls = [
    // Balance of token in the LP contract
    {
      address: getPresaleAddress(),
      name: 'isIcoCompleted',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'hasIcoPaused',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'isIcoFirstRoundCompleted',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'isIcoSecondRoundCompleted',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'limitPhaseOne',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'limitPhaseTwo',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'limitPhaseThree',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'startSecondRoundTime',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'startThirdRoundTime',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'startCrowdsaleTime',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'tokenAmountRateOne',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'tokenAmountRateTwo',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'tokenAmountRateThree',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'tokensRaised',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'has2ndRoundStarted',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'has3rdRoundStarted',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'minimumBuyAmount',
      params: [],
    },
    {
      address: getPresaleAddress(),
      name: 'whiteListSpots',
      params: [],
    }
  ]

  const [
    isIcoCompleted,
    hasIcoPaused,
    isIcoFirstRoundCompleted,
    isIcoSecondRoundCompleted,
    limitPhaseOne,
    limitPhaseTwo,
    limitPhaseThree,
    startSecondRoundTime,
    startThirdRoundTime,
    startCrowdsaleTime,
    tokenAmountRateOne,
    tokenAmountRateTwo,
    tokenAmountRateThree,
    tokensRaised,
    has2ndRoundStarted,
    has3rdRoundStarted,
    minimumBuyAmount,
    _whitelistSpots

  ] = await multicall(presaleABI, calls); 

  return {
    isIcoCompleted: isIcoCompleted[0],
    hasIcoPaused: hasIcoPaused[0],
    isIcoFirstRoundCompleted: isIcoFirstRoundCompleted[0],
    isIcoSecondRoundCompleted: isIcoSecondRoundCompleted[0],
    limitPhaseOne: new BigNumber(limitPhaseOne).toJSON(),
    limitPhaseTwo: new BigNumber(limitPhaseTwo).toJSON(),
    limitPhaseThree: new BigNumber(limitPhaseThree).toJSON(),
    startSecondRoundTime: new BigNumber(startSecondRoundTime).toJSON(),
    startThirdRoundTime: new BigNumber(startThirdRoundTime).toJSON(),
    timeUntilCrowdsaleStarts: new BigNumber(startCrowdsaleTime).toJSON(),
    tokenAmountRateOne: new BigNumber(tokenAmountRateOne).toJSON(),
    tokenAmountRateTwo: new BigNumber(tokenAmountRateTwo).toJSON(),
    tokenAmountRateThree: new BigNumber(tokenAmountRateThree).toJSON(),
    tokensRaised: new BigNumber(tokensRaised).toJSON(),
    has2ndRoundStarted : has2ndRoundStarted[0],
    has3rdRoundStarted : has3rdRoundStarted[0],
    minimumBuyAmount: new BigNumber(minimumBuyAmount).toJSON(),
    whitelistSpots: new BigNumber(_whitelistSpots).toJSON(),
  }
}

export default fetchPresaleData;

