/* eslint-disable no-param-reassign */
import BigNumber from 'bignumber.js'
import { createSlice } from '@reduxjs/toolkit'
import farmsConfig, { offsetCommunity } from 'config/constants/farms'
import fetchFarms from './fetchFarms'
import {
  fetchFarmUserEarningsMaster,
  fetchFarmUserEarliestUnlockMaster,
  fetchFarmUserAllowancesMaster,
  fetchFarmUserTokenBalancesMaster,
  fetchFarmUserStakedBalancesMaster,
  fetchFarmUserEarningsCommunity,
  fetchFarmUserEarliestUnlockCommunity,
  fetchFarmUserAllowancesCommunity,
  fetchFarmUserTokenBalancesCommunity,
  fetchFarmUserStakedBalancesCommunity,
} from './fetchFarmUser'
import { FarmsState, Farm } from '../types'

const initialState: FarmsState = { data: [...farmsConfig] }

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find((f) => f.pid === farm.pid)
        return { ...farm, ...liveFarmData }
      })
    },
    setFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl
        const _farm = state.data.find(x => x.pid === index)
        const id = state.data.indexOf(_farm)
        state.data[id] = { ..._farm, userData: userDataEl }
      })
    },
  },
})

// Actions
export const { setFarmsPublicData, setFarmUserData } = farmsSlice.actions

// Thunks
export const fetchFarmsPublicDataAsync = () => async (dispatch) => {
  const farms = await fetchFarms()
  dispatch(setFarmsPublicData(farms))
}
export const fetchFarmUserDataAsync = (account) => async (dispatch) => {
  const userFarmAllowancesMaster = await fetchFarmUserAllowancesMaster(account)
  const userFarmTokenBalancesMaster = await fetchFarmUserTokenBalancesMaster(account)
  const userStakedBalancesMaster = await fetchFarmUserStakedBalancesMaster(account)
  const userFarmEarningsMaster = await fetchFarmUserEarningsMaster(account)
  const earliestUnlockMaster = await fetchFarmUserEarliestUnlockMaster(account)
  const userFarmAllowancesCommunity = await fetchFarmUserAllowancesCommunity(account)
  const userFarmTokenBalancesCommunity = await fetchFarmUserTokenBalancesCommunity(account)
  const userStakedBalancesCommunity = await fetchFarmUserStakedBalancesCommunity(account)
  const userFarmEarningsCommunity = await fetchFarmUserEarningsCommunity(account)
  const earliestUnlockCommunity = await fetchFarmUserEarliestUnlockCommunity(account)
  const arrayOfUserDataObjects = farmsConfig.map(farm => {
    if (!farm.isCommunity)
      return {
        index: farm.pid,
        allowance: userFarmAllowancesMaster[farm.pid],
        tokenBalance: userFarmTokenBalancesMaster[farm.pid],
        stakedBalance: userStakedBalancesMaster[farm.pid],
        earnings: userFarmEarningsMaster[farm.pid],
        locked: new BigNumber(earliestUnlockMaster[farm.pid]).toNumber() > 0 ? userStakedBalancesMaster[farm.pid] : new BigNumber(0).toJSON(),
        unlocked: new BigNumber(earliestUnlockMaster[farm.pid]).toNumber() === 0 ?  userStakedBalancesMaster[farm.pid] : new BigNumber(0).toJSON(),
        earliestUnlock: earliestUnlockMaster[farm.pid]
      }
      return {
        index: farm.pid,
        allowance: userFarmAllowancesCommunity[farm.pid - offsetCommunity],
        tokenBalance: userFarmTokenBalancesCommunity[farm.pid - offsetCommunity],
        stakedBalance: userStakedBalancesCommunity[farm.pid - offsetCommunity],
        earnings: userFarmEarningsCommunity[farm.pid - offsetCommunity],
        locked: new BigNumber(earliestUnlockCommunity[farm.pid - offsetCommunity]).toNumber() > 0 ? userStakedBalancesCommunity[farm.pid - offsetCommunity] : new BigNumber(0).toJSON(),
        unlocked: new BigNumber(earliestUnlockCommunity[farm.pid - offsetCommunity]).toNumber() === 0 ?  userStakedBalancesCommunity[farm.pid - offsetCommunity] : new BigNumber(0).toJSON(),
        earliestUnlock: earliestUnlockCommunity[farm.pid - offsetCommunity]
    }
  })
  dispatch(setFarmUserData({ arrayOfUserDataObjects }))
}

export default farmsSlice.reducer
